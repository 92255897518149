<div class="delete-account wrapper">
  <p class="delete-account__title">{{ 'landings.deleteAccount.title' | translate }}</p>

  <p class="delete-account__text">{{ 'landings.deleteAccount.instruction' | translate }}</p>

  <img class="delete-account__image" src="assets/images/delete-account.jpeg" alt="" draggable="false">

  <p class="delete-account__text">{{ 'landings.deleteAccount.getInTouch' | translate }}
    <a href="mailto:hello@flytrendy.com">hello&#64;flytrendy.com</a></p>
</div>
