import { Component, DestroyRef, inject, OnInit } from '@angular/core';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ActivatedRoute, RouterLink } from '@angular/router';
import { CommonService } from '@web/services';
import { catchError } from 'rxjs';
import { APP_CONFIG } from '@app-config';

@Component({
  selector: 'web-activate-influencer',
  standalone: true,
  templateUrl: './activate-influencer.component.html',
  imports: [
    TranslateModule,
    RouterLink,
  ],
})
export class ActivateInfluencerComponent implements OnInit {
  private destroyRef = inject(DestroyRef);
  private route = inject(ActivatedRoute);
  private translate = inject(TranslateService);

  private config = inject(APP_CONFIG);
  private commonService = inject(CommonService);

  public redirectUrl = this.config.appRedirectUrl || null;

  public isActivateSuccess: boolean;
  public successMsg: string;
  public failureMsg: string;

  ngOnInit() {
    this.translate.stream('activateInfluencer')
    .pipe(takeUntilDestroyed(this.destroyRef))
    .subscribe(({ failActivated, success }) => {
      this.successMsg = success;
      this.failureMsg = failActivated;
    });

    this.route.queryParams
    .pipe(takeUntilDestroyed(this.destroyRef))
    .subscribe((params) => {
      const email = params['email'];
      const activationToken = params['activation_token'];

      this.commonService.activateInfluencer(email, activationToken)
      .pipe(catchError((error) => error))
      .subscribe(({ activateAccount }) => this.isActivateSuccess = activateAccount.success);
    });
  }

  public openApp(): void {
    window.open(this.redirectUrl);
  }
}
