import { Component } from '@angular/core';
import { RouterLink } from '@angular/router';
import { TitleCasePipe, UpperCasePipe } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'web-not-found',
  standalone: true,
  templateUrl: './not-found.component.html',
  imports: [ TranslateModule, RouterLink, TitleCasePipe, UpperCasePipe ],
})
export class NotFoundComponent {
}
