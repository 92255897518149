<div class="change-password wrapper">
  <span class="change-password__title">{{ 'changePassword.changePasswordTitle' | translate }}</span>

  <form class="change-password__form" [formGroup]="passwordForm">
    <web-form-field [errors]="errors('password')"
                    formControlName="password"
                    fieldLabel="New password"
                    type="password" />

    <web-form-field [errors]="errors('confirm')"
                    [errorMsg]="equivalentErrorMsg"
                    formControlName="confirm"
                    fieldLabel="Confirm new password"
                    type="password" />
  </form>

  <button class="change-password__btn button"
          type="submit"
          [disabled]="passwordForm.invalid"
          (click)="changePassword()">{{ 'changePassword.changePasswordTitle' | translate }}
  </button>
</div>
