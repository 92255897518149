import { Component } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'web-delete-account',
  standalone: true,
  templateUrl: './delete-account.component.html',
  imports: [ TranslateModule ],
})
export class DeleteAccountComponent {}
