<div class="activate-influencer wrapper">
  <div class="activate-influencer__block">
    <span class="activate-influencer__text">{{ isActivateSuccess ? successMsg : failureMsg }}</span>

    @if (redirectUrl) {
      <button class="activate-influencer__btn button" (click)="openApp()">
        {{ 'activateInfluencer.button' | translate }}
      </button>
    } @else {
      <button class="activate-influencer__btn button" routerLink="/">Home Page</button>
    }
  </div>
</div>
