import { ChangeDetectionStrategy, Component, inject, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DialogLoginComponent } from '@web/dialogs';

@Component({
  selector: 'web-home',
  standalone: true,
  templateUrl: './home.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HomeComponent implements OnInit {
  private dialog = inject(MatDialog);

  ngOnInit(): void {
    this.openLoginDialog();
  }

  private openLoginDialog(): void {
    this.dialog.open(DialogLoginComponent, {
      disableClose: true,
      backdropClass: 'mat-dialog-backdrop',
      panelClass: 'mat-dialog-panel',
    });
  }
}
