import { Component, inject } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DialogLoginComponent } from '@web/dialogs';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'web-login',
  standalone: true,
  templateUrl: './login.component.html',
  imports: [
    TranslateModule,
  ],
})
export class LoginComponent {
  private dialog = inject(MatDialog);

  public openLoginDialog(): void {
    this.dialog.open(DialogLoginComponent);
  }
}
