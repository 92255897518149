import { Component, DestroyRef, inject, OnInit } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ActivatedRoute } from '@angular/router';
import { FormBuilder, ReactiveFormsModule, Validators } from '@angular/forms';
import { TranslateModule, TranslateService } from '@ngx-translate/core';

import { FormFieldComponent } from '@web/common';
import { CommonService, SnackbarService } from '@web/services';

@Component({
  selector: 'web-change-password',
  standalone: true,
  templateUrl: './change-password.component.html',
  imports: [ ReactiveFormsModule, TranslateModule, FormFieldComponent ],
})
export class ChangePasswordComponent implements OnInit {
  private fb = inject(FormBuilder);
  private destroyRef = inject(DestroyRef);
  private route = inject(ActivatedRoute);
  private translate = inject(TranslateService);

  private commonService = inject(CommonService);
  private snackbarService = inject(SnackbarService);

  private accessToken: string;
  private verificationCode: string;

  public passwordForm = this.fb.group({
    password: [ '', [ Validators.required ] ],
    confirm: [ '', [ Validators.required ] ],
  }, {
    validators: [ this.commonService.checkIfMatchingPasswords('password', 'confirm') ],
  });

  ngOnInit() {
    this.route.queryParams
    .pipe(takeUntilDestroyed(this.destroyRef))
    .subscribe((params) => {
      this.accessToken = params['ac'];
      this.verificationCode = params['c'];
    });
  }

  public get equivalentErrorMsg(): string {
    const confirm = this.passwordForm.get('confirm');
    if (confirm.errors && confirm.errors['notEquivalent']) {
      return this.translate.instant('newCampaign.submitMsg.passwordMatch');
    }
    return null;
  }

  public errors(control: string): boolean {
    const invalid = this.passwordForm.get(control)!.invalid;
    const touched = this.passwordForm.get(control)!.touched;
    return invalid && touched;
  }

  public changePassword(): void {
    const password = this.passwordForm.value.password;
    if (!password) {
      return;
    }

    const successMsg = this.translate.instant('activateInfluencer.success');
    const failureMsg = this.translate.instant('commonMsg.somethingWentWrong');

    this.commonService.changePassword(this.accessToken, this.verificationCode, password)
    .subscribe(
      () => this.snackbarService.success(successMsg),
      (error) => error.error.text === 'True' ?
        this.snackbarService.success(successMsg) :
        this.snackbarService.failure(failureMsg));
  }
}
